<template>
  <div>
    Tìm kiếm ga tàu, giá vé
  </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>
